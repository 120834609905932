<template>
  <!-- blogs start -->
  <div class="blogs-page">
    <PageLoader />
    <!-- breadcrumb area start -->
    <div class="breadcrumb-area mb-30">
      <div class="container-fluied">
        <div class="row phones">
            <div class="img1">
                <div class="img1all">
                    <ol class="text-white bradcro">
                        <li class="link d-inline">
                             <router-link to="/" tag="a">Home</router-link> /</li>
                        <li class="d-inline">Search</li>
                    </ol>
                    <h1>Search Result</h1>
                </div>
            </div>
        </div>
      </div>
    </div>
    <!-- breadcrumb area end -->

    <!-- blog wrapper start -->
    <div class="blog-area-wrapper pt-5 pb-5">
      <div class="container">
        <div class="row">
          <div
            class="col-lg-12 order-first order-lg-last single_product page2 home_page home"
          >
            <div class="featured-categories-area pb-40 new_card pt-5">
              <ul class="row text-center mb-5 justify-content-center">
                <template v-for="(singleCategorys1, i) in pcb" >
                  <li :key="singleCategorys1.slug + i" class="col-md-3 Most_popular_phones_img mt-4 ">
                      <router-link
                        :to="
                          '/categories/' +
                            singleCategorys1.category.slug +
                            '/' +
                            singleCategorys1.brand.slug +
                            '/' +
                            singleCategorys1.slug +
                            '?product=' +
                            singleCategorys1.id
                        "
                        tag="a"
                      >
                        <img :src="publicPath + singleCategorys1.img" style="width: 8rem;height: auto;">
                        <br>
                        <br>
                        <span class="Most_popular_phones_span">{{ singleCategorys1.title }}</span>
                      </router-link>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- blog wrapper end -->
  </div>
  <!-- blogs end -->
</template>
<script>
import PageLoader from "@/components/units/PageLoader.vue";
export default {
  name: "newsCopm",
  components: {
    PageLoader
  },
  data() {
    return {
      publicPath: "https://app.contractsexpert.co.uk/storage/app/public/",
      pcb: []
    };
  },
  mounted() {
   window.scrollTo(0, 0)
    this.getData();
  },
  computed: {
    getFullPath() {
      return this.$route.path;
    },
    getquery() {
      return this.$route.query;
    },
    returnBrand() {
      return this.$route.params.brand;
    },
    returnCat() {
      return this.$route.params.id;
    }
  },
  watch: {
    getFullPath() {
      this.getData();
    },
    getquery() {
      this.getData();
    }
  },
  methods: {
    getData() {
      this.loading = true;
      this.$http
        .get(
          "search?cat_id=" +
            this.$route.query.cat_id +
            "&input=" +
            this.$route.query.input
        )
        .then(response => {
          this.pcb = response.data.data;
          response => (this.pcb = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
