var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"blogs-page"},[_c('PageLoader'),_c('div',{staticClass:"breadcrumb-area mb-30"},[_c('div',{staticClass:"container-fluied"},[_c('div',{staticClass:"row phones"},[_c('div',{staticClass:"img1"},[_c('div',{staticClass:"img1all"},[_c('ol',{staticClass:"text-white bradcro"},[_c('li',{staticClass:"link d-inline"},[_c('router-link',{attrs:{"to":"/","tag":"a"}},[_vm._v("Home")]),_vm._v(" /")],1),_c('li',{staticClass:"d-inline"},[_vm._v("Search")])]),_c('h1',[_vm._v("Search Result")])])])])])]),_c('div',{staticClass:"blog-area-wrapper pt-5 pb-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 order-first order-lg-last single_product page2 home_page home"},[_c('div',{staticClass:"featured-categories-area pb-40 new_card pt-5"},[_c('ul',{staticClass:"row text-center mb-5 justify-content-center"},[_vm._l((_vm.pcb),function(singleCategorys1,i){return [_c('li',{key:singleCategorys1.slug + i,staticClass:"col-md-3 Most_popular_phones_img mt-4 "},[_c('router-link',{attrs:{"to":'/categories/' +
                          singleCategorys1.category.slug +
                          '/' +
                          singleCategorys1.brand.slug +
                          '/' +
                          singleCategorys1.slug +
                          '?product=' +
                          singleCategorys1.id,"tag":"a"}},[_c('img',{staticStyle:{"width":"8rem","height":"auto"},attrs:{"src":_vm.publicPath + singleCategorys1.img}}),_c('br'),_c('br'),_c('span',{staticClass:"Most_popular_phones_span"},[_vm._v(_vm._s(singleCategorys1.title))])])],1)]})],2)])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }